let page = 1;
let isMobile = false;

let sliderInterval;
let signUpPage = 5;
let animationTimeout;
let circleClicked = false;

const pages = {
  "deep-learning": 1,
  "slider-info": 2,
  benefits: 3,
  management: 4,
  signup: signUpPage,
};
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.defaults({
  start: () => "top 40%",
  end: "bottom 40%",
  scrub: 0.5,
});

let tlDeepLearning = gsap.timeline();
tlDeepLearning.add(
  TweenLite.fromTo(
    ".animation-elements .lines",
    {
      x: "-5%",
    },
    {
      x: "88%",
    }
  ),
  0
);
tlDeepLearning.add(
  TweenLite.fromTo(
    ".animation-elements .vertical-line-wrapper",
    {
      y: "-5%",
    },
    {
      y: "95%",
    }
  ),
  0
);
tlDeepLearning.add(
  TweenLite.fromTo(
    ".animation-elements .scroller",
    {
      y: "24px",
      width: "6px",
      height: "90px",
    },
    {
      y: "24px",
      width: "6px",
      height: "90px",
    }
  ),
  0
);
ScrollTrigger.create({
  animation: tlDeepLearning,
  trigger: "#deep-learning",
  toggleActions: "restart complete reverse pause",
  start: () => "top 5%",
  end: "bottom 5%",
});
ScrollTrigger.create({
  trigger: "#deep-learning",
  toggleActions: "restart complete reverse pause",
  start: () => "40% 45%",
  end: "bottom 45%",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("deep-learning");
    }
  },
});

let btns = gsap.timeline({ paused: true });

btns.add(
  TweenLite.fromTo(
    "#header-btn",
    {
      x: "200%",
      opacity: 0,
    },
    {
      x: "0%",
      opacity: 1,
    }
  ),
  0
);
btns.add(
  TweenLite.fromTo(
    "#login",
    {
      x: "0%",
      opacity: 1,
    },
    {
      x: "200%",
      opacity: 0,
    }
  ),
  0
);
let signupBtns = gsap.timeline({ paused: true });

ScrollTrigger.create({
  trigger: "#slider-info",
  scrub: false,
  start: "40% center",
  id: "BTN",
  onEnter: () => {
    if (!circleClicked) {
      signupBtns.reverse();
      btns.play();
    }
  },
  onLeaveBack: () => {
    if (!circleClicked) {
      btns.reverse();
    }
  },
});

signupBtns.add(
  TweenLite.fromTo(
    "#header-btn .trial",
    {
      x: "0%",
    },
    {
      x: "200%",
    }
  ),
  0
);
signupBtns.add(
  TweenLite.fromTo(
    "#header-btn .demo",
    {
      x: "0%",
      opacity: 1,
    },
    {
      x: "110%",
      opacity: 1,
    }
  ),
  0
);
ScrollTrigger.create({
  trigger: "#signup",
  scrub: false,
  start: "20% center",
  id: "BTN2",
  onEnter: () => {
    if (!circleClicked) {
      signupBtns.play();
    }
  },
  onLeaveBack: () => {
    if (!circleClicked) {
      signupBtns.reverse();
    }
  },
});

let tlSliderInfo = gsap.timeline();

tlSliderInfo.add(
  TweenLite.fromTo(
    ".animation-elements .lines",
    {
      x: "88%",
    },
    {
      x: "12%",
    }
  ),
  0
);
tlSliderInfo.add(
  TweenLite.fromTo(
    ".animation-elements .scroller",
    {
      y: "24px",
      width: "6px",
      height: "90px",
    },
    {
      y: "24px",
      width: "6px",
      height: "90px",
    }
  ),
  0
);
tlSliderInfo.add(
  TweenLite.fromTo(
    ".animation-elements .vertical-line-wrapper",
    {
      y: "95%",
    },
    {
      y: "75%",
    }
  ),
  0
);
ScrollTrigger.create({
  animation: tlSliderInfo,
  trigger: "#slider-info",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("slider-info");
    }
  },
});

ScrollTrigger.create({
  trigger: "#benefits",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("benefits");
    }
  },
});

let tlManagement = gsap.timeline();
tlManagement.add(
  TweenLite.fromTo(
    ".animation-elements .lines",
    {
      x: "12%",
    },
    {
      x: "50%",
    }
  ),
  0
);
tlManagement.add(
  TweenLite.fromTo(
    ".animation-elements .scroller",
    {
      y: "24px",
      width: "6px",
      height: "90px",
    },
    {
      y: "24px",
      width: "16px",
      height: "90px",
    }
  ),
  0
);
tlManagement.add(
  TweenLite.fromTo(
    ".animation-elements .vertical-line-wrapper",
    {
      y: "75%",
    },
    {
      y: "75%",
    }
  ),
  0
);
ScrollTrigger.create({
  animation: tlManagement,
  trigger: "#management",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("management");
    }
  },
});

let tlDashboards = gsap.timeline();
tlDashboards.add(
  TweenLite.fromTo(
    ".animation-elements .lines",
    {
      x: "50%",
    },
    {
      x: "75%",
    }
  ),
  0
);
tlDashboards.add(
  TweenLite.fromTo(
    ".animation-elements .scroller",
    {
      y: "24px",
      width: "16px",
      height: "90px",
    },
    {
      y: "200px",
      width: "16px",
      height: "30px",
    }
  ),
  0
);
tlDashboards.add(
  TweenLite.fromTo(
    ".animation-elements .vertical-line-wrapper",
    {
      y: "75%",
    },
    {
      y: "75%",
    }
  ),
  0
);
ScrollTrigger.create({
  animation: tlDashboards,
  trigger: "#dashboards",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("management");
    }
  },
});

let tlAnalysis = gsap.timeline();
tlAnalysis.add(
  TweenLite.fromTo(
    ".animation-elements .lines",
    {
      x: "75%",
    },
    {
      x: "88%",
    }
  ),
  0
);
tlAnalysis.add(
  TweenLite.fromTo(
    ".animation-elements .scroller",
    {
      y: "200px",
      width: "16px",
      height: "30px",
    },
    {
      y: "16px",
      width: "16px",
      height: "30px",
    }
  ),
  0
);
tlAnalysis.add(
  TweenLite.fromTo(
    ".animation-elements .vertical-line-wrapper",
    {
      y: "75%",
    },
    {
      y: "75%",
    }
  ),
  0
);
ScrollTrigger.create({
  animation: tlAnalysis,
  trigger: "#analysis",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("management");
    }
  },
});

let tlOptimization = gsap.timeline();
tlOptimization.add(
  TweenLite.fromTo(
    ".animation-elements .lines",
    {
      x: "88%",
    },
    {
      x: "105%",
    }
  ),
  0
);
tlOptimization.add(
  TweenLite.fromTo(
    ".animation-elements .scroller",
    {
      y: "16px",
      width: "16px",
      height: "30px",
    },
    {
      y: "16px",
      width: "16px",
      height: "30px",
    }
  ),
  0
);
tlOptimization.add(
  TweenLite.fromTo(
    ".animation-elements .vertical-line-wrapper",
    {
      y: "75%",
    },
    {
      y: "100%",
    }
  ),
  0
);
ScrollTrigger.create({
  animation: tlOptimization,
  trigger: "#optimization",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("management");
    }
  },
});

ScrollTrigger.create({
  trigger: "#signup",
  id: "signup",
  onToggle: ({ isActive }) => {
    if (isActive) {
      changeActivePageIndicator("signup");
    }
  },
});

let tlImageManagement = gsap.timeline({
  scrollTrigger: {
    trigger: "#management",
    start: "center 80%",
    scrub: true,
    end: "center center",
  },
});
tlImageManagement.add(
  TweenLite.fromTo("#management .colored", 2, { opacity: 0 }, { opacity: 1 }),
  0
);
// tlImageManagement.add(TweenLite.fromTo('#management .colored', 2, {opacity: 1}, {opacity: 0}), 2)
let tlImageDashboards = gsap.timeline({
  scrollTrigger: {
    trigger: "#dashboards",
    start: "center 80%",
    scrub: true,
    toggleActions: "restart pause reverse pause",
    end: "center center",
  },
});
tlImageDashboards.add(
  TweenLite.fromTo("#dashboards .colored", 2, { opacity: 0 }, { opacity: 1 }),
  0
);
// tlImageDashboards.add(TweenLite.fromTo('#dashboards .colored', 2, {opacity: 1}, {opacity: 0}), 2)

let tlImageAnalysis = gsap.timeline({
  scrollTrigger: {
    trigger: "#analysis",
    start: "center 80%",
    scrub: true,
    end: "center center",
  },
});
tlImageAnalysis.add(
  TweenLite.fromTo("#analysis .colored", 2, { opacity: 0 }, { opacity: 1 }),
  0
);
// tlImageAnalysis.add(TweenLite.fromTo('#analysis .colored', 2, {opacity: 1}, {opacity: 0}), 2)

let tlImageOptimization = gsap.timeline({
  scrollTrigger: {
    trigger: "#optimization",
    start: "center 80%",
    scrub: true,
    end: "center center",
  },
});
tlImageOptimization.add(
  TweenLite.fromTo("#optimization .colored", 2, { opacity: 0 }, { opacity: 1 }),
  0
);
// tlImageOptimization.add(TweenLite.fromTo('#optimization .colored', 2, {opacity: 1}, {opacity: 0}), 2)

$(".change-page").on("click", function (e) {
  e.preventDefault();
  let link = $(e.currentTarget).get(0);
  let pageid = $(link).data("pageid");
  page = pages[pageid];
  circleClicked = true;
  moveToPage(pageid, page);
});

$(".view-demo").on("click", function (e) {
  let link = $(e.currentTarget).get(0);
  let popupOrigin = $(link).data("popup-origin");
  viewDemoBtn(popupOrigin);
});
$("#live-demo-email input[name=demo-email]").on("keydown", function (e) {
  if (e.code == "Enter" || e.key === "Enter") {
    $("#live-demo-email").trigger("submit");
  }
});

$("#live-demo-email").on("submit", function (e) {
  e.preventDefault();
  $(".form-error").css("display", "none");
  $("#live-demo-email").find(".error").css("display", "none");
  $("#live-demo-email").find(".check-mark").css("display", "none");
  let errors = false;
  const email = $(`#live-demo-email input[name=demo-email]`).val();
  if (!isValidEmailAddress(email)) {
    $("#live-demo-email input[name=demo-email]")
      .siblings(".form-error")
      .css("display", "block");
    errors = true;
  }

  const baseUrl = `https://${
    document.location.host.startsWith("landing-page") ? "staging" : "trial"
  }.tensorleap.ai`;
  const url = `${baseUrl}/api/landing/startDemo`;
  const redirectUrl = `${baseUrl}/?demoMode=1`;
  if (!errors) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.withCredentials = true;
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(JSON.stringify({ email: email }));
    xhr.onload = function () {
      setTimeout(() => {
        window.open(redirectUrl, "_self");
      }, 100);
    };
    xhr.onerror = function () {
      $("#live-demo-email").find(".error").css("display", "inline-flex");
      $("#live-demo-email").find(".check-mark").css("display", "none");
    };
  }
});

$(".live-demo-modal .overlay, .live-demo-modal .close-dialog").on(
  "click",
  function (e) {
    $(".demo-modal").fadeToggle();
    setTimeout(() => {
      $("#live-demo-email").find(".error").css("display", "none");
      $("#live-demo-email").find(".check-mark").css("display", "none");
    }, 500);
  }
);

$(".openbtn").on("click", function (e) {
  openNav();
});

$(".closebtn").on("click", function (e) {
  closeNav();
});

var loopStartTime = null;
let loopCheckTimeout;
var animationLoop = (t) => {
  if (!loopStartTime) {
    loopStartTime = t;
  }
  let diff = t - loopStartTime;
  if (diff >= 11200) {
    var el = document.querySelector(".animate-box-wrap");
    removeClass()
      .then(function () {
        setTimeout(function () {
          el.classList.add("animate-start");
        }, 1);
      })
      .catch(function () {
        el.classList.remove("animate-start");
        setTimeout(function () {
          el.classList.add("animate-start");
        }, 1);
      });
    clearTimeout(loopCheckTimeout);
    window.requestAnimationFrame(animationLoop);
    loopStartTime = t;
    return;
  }
  loopCheckTimeout = setTimeout(() => {
    window.requestAnimationFrame(animationLoop);
  }, 500);
};

function animate() {
  animationTimeout = setTimeout(() => {
    var el = document.querySelector(".animate-box-wrap");
    el.classList.add("animate-start");
    window.requestAnimationFrame(animationLoop);
  }, 2000);
}

function removeClass() {
  return new Promise(function (resolve, reject) {
    var el = document.querySelector(".animate-box-wrap");
    el.classList.remove("animate-start");
    if (!el.classList.contains("animate-start")) {
      resolve();
    } else {
      el.classList.remove("animate-start");
      reject();
    }
  });
}

animate();
// detect if mobile browser. regex -> http://detectmobilebrowsers.com
isMobile = (function (a) {
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    )
  );
})(navigator.userAgent || navigator.vendor || window.opera);

function changeActivePageIndicator(id) {
  if (!circleClicked) {
    $(`.menu-item`).removeClass("active");
    $(`.${id}`).addClass("active");
  }
}

function moveToPage(id, p = 0, duration = 1500) {
  $(`.menu-item`).removeClass("active");
  $(`.${id}`).addClass("active");

  let top = $(`#${id}`).offset().top - $(`#${id}`).parent().offset().top;
  let left = $(`#${id}`).offset().left - $(`#${id}`).parent().offset().left;
  $("html, body").animate(
    {
      scrollTop: top,
      scrollLeft: left,
    },
    {
      duration: duration,
      specialEasing: {
        width: "linear",
        height: "easeOutBounce",
      },
      complete: function () {
        circleClicked = false;
      },
    }
  );
  if (p === 1) {
    btns.reverse();
  }
  if (p !== 1 && p !== signUpPage) {
    btns.play();
    signupBtns.reverse();
  }
  if (p === signUpPage) {
    btns.play();
    signupBtns.play();
  }
}

$("#signup form").on("submit", function (event) {
  event.preventDefault();
  let errors = false;
  $(".form-error").css("display", "none");
  let formId = "#signup";
  const name = $(`${formId} input[name=name]`).val();
  const email = $(`${formId} input[name=email]`).val();
  const password = $(`${formId} input[name=password]`).val();
  const confirmPassword = $(`${formId} input[name=confirmPassword]`).val();
  const company = $(`${formId} input[name=company]`).val();

  if (!isValidEmailAddress(email)) {
    $("input[name=email]").siblings(".form-error").css("display", "block");
    errors = true;
  }
  if (!isValidPassword(password)) {
    $("input[name=password]").siblings(".form-error").css("display", "block");
    errors = true;
  }
  if (password !== confirmPassword) {
    $("input[name=confirmPassword]")
      .siblings(".form-error")
      .css("display", "block");
    errors = true;
  }

  const trial = {
    metadata: {},
  };
  trial.metadata.company = company;
  const data = {
    name,
    email,
    password,
    requestTrialData: trial,
  };
  const url = `https://${
    document.location.host.startsWith("landing-page") ? "staging" : "trial"
  }.tensorleap.ai/api/landing/requestTrial`;
  if (!errors) {
    $.ajax({
      url: url,
      type: "POST",
      data: JSON.stringify(data),
      processData: false,
      contentType: "application/json",
    })
      .done(function (data) {
        $(`${formId}`).find(".check-mark").css("display", "inline-flex");
        $(`${formId}`).find(".error").css("display", "none");
      })
      .fail((err) => {
        if (err.responseJSON?.error) {
          $(`${formId}`).find(".error").html(err.responseJSON?.error);
        }
        $(`${formId}`).find(".error").css("display", "inline-flex");
        $(`${formId}`).find(".check-mark").css("display", "none");
      });
  }
});

function isValidEmailAddress(emailAddress) {
  var pattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
  return pattern.test(emailAddress);
}

function isValidPassword(password) {
  return password.length >= 8;
}

function openNav() {
  document.getElementById("slideMenu").classList.add("active");
}

function closeNav() {
  document.getElementById("slideMenu").classList.add("deactivate");
  setTimeout(() => {
    document.getElementById("slideMenu").classList.remove("active");
  }, 250);
  setTimeout(() => {
    document.getElementById("slideMenu").classList.remove("deactivate");
  }, 500);
}

function viewDemoBtn(id) {
  if (isMobile) {
    var popup = document.getElementById(id);
    popup.classList.toggle("show");
    setTimeout(() => {
      popup.classList.toggle("show");
    }, 4000);
    return;
  }
  $(".demo-modal").fadeToggle().css("display", "flex");
  $(".demo-modal input[name=demo-email]").trigger("focus");
}

let slideIndex = 1;
let sliderStart;
let sliderAnimationTimeout;
showDivs(slideIndex);

function plusDivs(n) {
  showDivs((slideIndex += n));
}

function sliderAnimation(timestamp) {
  if (!sliderStart) sliderStart = timestamp;
  let progress = timestamp - sliderStart;
  if (progress >= 10000) {
    plusDivs(1);
    clearTimeout(sliderAnimationTimeout);
    window.requestAnimationFrame(sliderAnimation);
    sliderStart = timestamp;
    return;
  }
  sliderAnimationTimeout = setTimeout(() => {
    window.requestAnimationFrame(sliderAnimation);
  }, 100);
}
window.requestAnimationFrame(sliderAnimation);

function showDivs(n) {
  var dots = document.getElementsByClassName("dots");
  var x = document.getElementsByClassName("slide");
  var schemaPart = document.getElementsByClassName("slide-part");
  if (n > x.length) {
    slideIndex = 1;
  }
  if (n < 1) {
    slideIndex = x.length;
  }
  x[slideIndex - 1].style.display = "flex";
  x[slideIndex - 1].classList.add("active");
  $(schemaPart[slideIndex - 1]).fadeIn(0);
  // schemaPart[slideIndex-1].style.display = "block";
  for (let i = 0; i < x.length; i++) {
    dots[i].classList.remove("active");
    if (i !== slideIndex - 1) {
      x[i].style.display = "none";
      x[i].classList.remove("active");
      $(schemaPart[i]).fadeOut(0);
      // schemaPart[i].style.display = "none";
    }
  }
  dots[slideIndex - 1].classList.add("active");
}

const dots = document.getElementById("dots-container");
dots.addEventListener(
  "click",
  (event) => {
    let index = 0;
    if (event.target.classList.contains("dots")) {
      index = [...event.target.parentElement.children].indexOf(event.target);
      showDivs((slideIndex = index + 1));
      sliderStart = 0;
      window.requestAnimationFrame(sliderAnimation);
    }
  },
  false
);

function DropDown(el) {
  this.id = el;
  this.activeSelect = null;
  this.initEvents();
}
DropDown.prototype = {
  initEvents: function () {
    var obj = this;
    let objEl = $(`#${obj.id}`);
    objEl.on("click", function (event) {
      $(this).toggleClass("active");
      event.stopPropagation();
    });
    $(`#${obj.id} .select`).on("click", function (event) {
      this.activeSelect = event.target.innerText;
      $(`#${obj.id} .select`).removeClass("active");
      $(event.target).addClass("active");
      $(`#signup input[name=status]`).val(this.activeSelect);
      $(".activeSelect").text(this.activeSelect);
    });
  },
};

let dropDown = new DropDown("deep-learning-drop-down");
